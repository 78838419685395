<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="项目">
        <el-input v-model="filter.project" placeholder="编号、项目名称、拼音" clearable></el-input>
      </el-form-item>
      <el-form-item label="立项日期">
        <el-date-picker
          v-model="filter.billDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="项目状态">
        <el-select v-model="filter.billState" placeholder="项目状态" clearable>
          <el-option
            v-for="item in billState"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-plus" @click="addProject" type="primary">添加</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="data" stripe border @row-dblclick="dblclickTable">
      <el-table-column label="项目编号" prop="billCode" show-overflow-tooltip></el-table-column>
      <el-table-column label="立项日期" prop="billDate" width="90">
        <template slot-scope="scope">{{scope.row.billDate|datetime}}</template>
      </el-table-column>
      <el-table-column label="开始日期" prop="startDate" width="90">
        <template slot-scope="scope">{{scope.row.startDate|datetime}}</template>
      </el-table-column>
      <el-table-column label="结束日期" prop="endDate" width="90">
        <template slot-scope="scope">{{scope.row.endDate|datetime}}</template>
      </el-table-column>
      <el-table-column label="项目名称" prop="projectName" show-overflow-tooltip></el-table-column>
      <el-table-column label="项目状态">
        <template slot-scope="scope">{{getBillStateLabel(scope.row.billState)}}</template>
      </el-table-column>
      <el-table-column label="预算">
        <el-table-column label="物资" prop="goodsBudget">
          <template slot-scope="scope">{{scope.row.goodsBudget|thousands}}</template>
        </el-table-column>
        <el-table-column label="费用" prop="expenseBudget">
          <template slot-scope="scope">{{scope.row.expenseBudget|thousands}}</template>
        </el-table-column>
        <el-table-column label="人工" prop="laborBudget">
          <template slot-scope="scope">{{scope.row.laborBudget|thousands}}</template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="项目说明" prop="remark" show-overflow-tooltip></el-table-column>
      <el-table-column label="项目地址" prop="address" show-overflow-tooltip></el-table-column>
      <el-table-column label="指派操作员" prop="assignOperName" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" width="65">
        <template slot-scope="scope">
          <el-tag type="warning" effect="dark" @click="editProject(scope.row)">变更</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 50, 100, 500]"
      @size-change="getData(1)"
      @current-change="getData()"
      :current-page.sync="pageIndex"
      :page-size.sync="pageSize"
      :total="pageTotal"
    ></el-pagination>
    <div>
      <add-edit :item="item" @success="getData()"></add-edit>
    </div>
  </div>
</template>

<script>
import billState from "@/views/project/BillState.js";
import AddEdit from "@/views/project/Item.vue";
import setName from "@/common/setName";
export default {
  components: {
    AddEdit,
  },
  data() {
    return {
      filter: {},
      billState: billState,
      loading: false,
      data: [],
      pageIndex: 1,
      pageSize: 10,
      pageTotal: 0,
      item: {},
    };
  },
  watch: {
    filter: {
      handler: function (v) {
        this.getData(1);
      },
      deep: true,
    },
  },
  activated() {
    this.getData();
  },
  methods: {
    getBillStateLabel(value) {
      let res = "";
      billState.forEach((item) => {
        if (item.value == value) {
          res = item.label;
        }
      });
      return res;
    },
    getData(pageIndex) {
      if (pageIndex) {
        this.pageIndex = pageIndex;
      }
      this.loading = true;
      let params = {
        ...this.filter,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };
      this.$get("Project/GetAllProject", params)
        .then((r) => {
          let data = r;
          data.list.forEach((item) => {
            setName("Operator", item, "assignOper", "assignOperName");
          });
          this.data = data.list;
          this.pageTotal = data.pageTotal;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addProject() {
      this.item = {};
    },
    editProject(row) {
      this.item = {
        id: row.id,
      };
    },
    dblclickTable(row) {
      this.$router.push({
        name: "ProjectDetail",
        params: { id: row.id },
      });
    },
  },
};
</script>

<style>
</style>